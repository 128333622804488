@import "@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as";
@import "@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing";
@import "@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss";
@import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss";

@mixin capitals-wide {
  @include display-as("capitals");
  color: var(--textColour);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 3px;
}

.rateSaleCountdown {
  background-color: var(--backgroundColour);
  padding: spacing(2) spacing(0.5);
  width: fit-content;
  border-radius: spacing(1);
  text-align: center;

  @include breakpointFS(medium) {
    padding: spacing(2) spacing(3);
  }

  @include breakpointFS(large) {
    padding: spacing(2) spacing(2);
  }

  .title {
    max-width: 264px;
    margin-bottom: spacing(1);
    @include capitals-wide;

    @include breakpointFS(medium) {
      max-width: 280px;
    }

    @include breakpointFS(large) {
      max-width: 320px;
    }

    img {
      margin-right: spacing(1);
      vertical-align: text-bottom;
    }
  }

  .timer {
    margin-bottom: spacing(2);
  }

  .countdownContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: spacing(2);

    .timeUnitContainer {
      width: 77px;
    }

    .timeValue {
      padding-bottom: spacing(1);
    }

    .timeValue,
    .separator {
      @include display-as("heading-l");
      color: var(--textColour);

      @include breakpointFS(large) {
        @include display-as("heading-xl");
        color: var(--textColour);
      }
    }

    .separator {
      padding: 0 spacing(0.5);

      @include breakpointFS(large) {
        padding: 0 spacing(2);
      }
    }

    .timeUnit {
      @include capitals-wide;
    }

    .mobileText {
      @include breakpointFS(large) {
        display: none;
      }
    }

    .desktopText {
      display: none;

      @include breakpointFS(large) {
        display: block;
      }
    }
  }

  .footerLabel {
    @include display-as("small-text-body");
    color: var(--textColour);
  }
}
