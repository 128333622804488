@import "@JohnLewisPartnership/jlfs-ui-library/dist/styles/breakpoints.scss";
@import "@JohnLewisPartnership/jl-design-system/dist/scss/core/spacing";
@import "@JohnLewisPartnership/jl-design-system/dist/style/typography/display-as";
@import "@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss";

.heading {
  display: grid;
  grid-template-columns: 7fr 2fr 2fr 2fr 2fr 32px;
  border-bottom: none;
  text-align: center;
  text-wrap: balance;
  @include display-as("heading-s");
  grid-template-areas:
    "currency sell-rate sell-rate sell-rate buy-rate empty"
    "blank under-500 over-500 over-1000 blank2 empty";
}

.row {
  display: grid;
  grid-template-columns: 7fr 2fr 2fr 2fr 2fr 32px;
  border-bottom: 1px solid $colour-taupe-100;
  background-color: $colour-fresh-white-100;

  @include breakpointFS(large) {
    background-color: unset;
    border-bottom: none;
  }

  & > :nth-child(3),
  & > :nth-child(5) {
    background-color: $colour-taupe-light-tint-50;
  }

  & > :nth-child(4),
  & > :nth-child(6) {
    background-color: $colour-fresh-white-100;
  }
}

.removeCustomRate {
  border: none;
  padding-left: spacing(1);
  align-items: center;

  & img {
    height: 24px;
    width: 24px;
  }
}

.removeCustomRateDesktop {
  display: none;

  @include breakpointFS(large) {
    display: flex;
  }
}

.removeCustomRateMobile {
  display: flex;
  padding-right: spacing(1.5);

  @include breakpointFS(large) {
    display: none;
  }
}

.currencyNameWrapper {
  display: flex;
  padding-top: spacing(3);
  padding-bottom: spacing(2);
  justify-content: space-between;
  background-color: $colour-fresh-white-100;
  border-bottom: none;

  @include breakpointFS(large) {
    border-bottom: 1px solid $colour-taupe-100;
    padding-top: spacing(2);
  }
}

.dropdownAnchor {
  position: relative;
  border-bottom-right-radius: spacing(1);
}

.currencyDropdownContainer {
  position: absolute;
  top: -40px;
  left: 300px;
  background-color: $colour-fresh-white-100;
}

.addCurrencyRow {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;

  @include breakpointFS(large) {
    grid-template-columns: 1fr 32px;
  }

  .addCurrencyContent {
    padding: spacing(3) spacing(1.5);
    border-bottom-left-radius: spacing(1);
    border-bottom-right-radius: spacing(1);
    background-color: $colour-fresh-white-100;

    @include breakpointFS(large) {
      padding: spacing(2) + spacing(0.5) spacing(2);
    }
  }
}

.addCurrencyButton {
  display: flex;
  align-items: center;
  border: none;
  background-color: $colour-fresh-white-100;
  padding: 0;
  cursor: pointer;
  & > span {
    text-decoration: underline;
  }
  & img {
    height: 18px;
    width: 18px;
    margin-right: spacing(1);
  }
}

.rateSaleMobile {
  background-color: $colour-neon-green-100;
  padding: spacing(0.5) spacing(1);
  margin-left: spacing(1.5);
  border-radius: spacing(2.5);
  display: flex;
  width: max-content;
  margin-bottom: spacing(1.5);

  @include breakpointFS(large) {
    display: none;
  }
}

.rateSaleDesktop {
  background-color: $colour-neon-green-100;
  padding: spacing(0.5) spacing(1);
  margin-left: spacing(1);
  border-radius: spacing(2.5);
  display: none;
  width: max-content;

  @include breakpointFS(large) {
    display: flex;
  }
}

.sellRateHeadingMobile {
  @include display-as("heading-xs");
  padding: spacing(1) spacing(0.5) spacing(1) spacing(1.5);

  @include breakpointFS(large) {
    display: none;
  }
}

.buyRateHeadingMobile {
  @include display-as("heading-xs");
  margin: spacing(1) spacing(0.5);
  padding-left: spacing(1);
}

.rateSaleText {
  margin-left: spacing(0.5);
  @include display-as("capitals");
}

.removeBuyRate {
  .heading {
    grid-template-columns: 6fr 2fr 2fr 2fr 32px;

    grid-template-areas:
      "currency sell-rate sell-rate sell-rate empty"
      "blank under-500 over-500 over-1000 empty";
  }
  .row {
    grid-template-columns: 6fr 2fr 2fr 2fr 32px;
  }

  .buyRate {
    display: none;
  }
  .buyRateBlock {
    display: none;
  }
}

.body {
  border-top-left-radius: spacing(1);
  border-top-right-radius: spacing(1);
  overflow: hidden;
  
  @include breakpointFS(large) {
    & > .row:first-child > div > div:first-child {
      border-top-left-radius: spacing(1);
    }
  
    & > .row:first-child div:nth-child(6) {
      border-top-right-radius: spacing(1);
      overflow: hidden;
    }
  }
}

.row .buyRate,
.row .over500 {
  background-color: unset;
}

.buyRate {
  grid-area: "buy-rate";
  align-content: center;
  justify-self: center;
}

.under500,
.over500,
.over1000 {
  padding: spacing(1) 0;
  @include display-as("body-l");
  text-align: center;
}

.under500 {
  grid-area: under-500;
  border-left: 1px solid $colour-taupe-100;
}

.over500 {
  grid-area: over-500;
  border-left: 1px solid $colour-taupe-100;
  border-right: 1px solid $colour-taupe-100;
}

.over1000 {
  grid-area: over-1000;
  border-right: 1px solid $colour-taupe-100;
}

.headerCurrency {
  grid-area: currency;
  text-align: left;
  padding-top: spacing(1);
  padding-bottom: spacing(1);
}

.sellRate {
  grid-area: sell-rate;
  text-align: center;
  padding-top: spacing(2);
  padding-bottom: spacing(2);
  border-left: 1px solid $colour-taupe-100;
  border-right: 1px solid $colour-taupe-100;
}

.currencyName {
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: spacing(1.5);

  @include breakpointFS(large) {
    padding-left: spacing(2);
  }
}

.flagWrapper {
  width: 26px;
  height: 20px;
  border: 1px solid $colour-heritage-green-100;
  margin-right: spacing(1);

  img {
    width: 100%;
    object-fit: cover;
  }
}

.sellRateBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $colour-taupe-100;
}

.buyRateBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colour-taupe-light-tint-50;
  border-bottom: 1px solid $colour-taupe-100;
}

.buyRateLabel {
  display: none;
}

.effectiveDateWrapper {
  color: $colour-heritage-green-100;
  margin-top: spacing(3);
  span:last-child {
    margin-left: spacing(1) * 0.75;
  }
}

.sellRateLabel {
  display: none;
}

.callToAction {
  padding-top: spacing(7);
  margin: 0 auto;

  @include breakpointFS(medium) {
    width: fit-content;
  }
}

@media (max-width: 967px) {
  .row {
    grid-template-columns: 1fr;
    padding-bottom: spacing(2);
  }

  .heading {
    grid-template-columns: 1fr;
    padding-bottom: spacing(1);
  }

  .removeBuyRate {
    .heading {
      grid-template-columns: 1fr;
    }
    .row {
      grid-template-columns: 1fr;
    }
  }

  .body {
    background-color: $colour-fresh-white-100;
  }

  .currencyName {
    border-bottom: none;
  }

  .sellRate {
    display: none;
  }

  .sellRateLabel {
    display: block;
  }

  .buyRateLabel {
    display: block;
    @include display-as("heading-xs");
  }

  .sellRateBlock {
    border-bottom: none;
    justify-content: space-between;
    margin: 0 spacing(0.5);
    padding: spacing(1);
  }

  .buyRateBlock {
    border-bottom: none;
    justify-content: space-between;
    margin: 0 spacing(0.5);
    padding: spacing(1);
  }

  .under500 {
    display: none;
  }
  .over500 {
    display: none;
  }
  .over1000 {
    display: none;
  }
  .buyRate {
    display: none;
  }
}
