@import "@JohnLewisPartnership/style-dictionary/dist/scss/financial-services/_colour.scss";

.removeRateIcon {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    display: flex;

    svg {
        color: $colour-heritage-green-100;
        
        &:hover {
            color: $colour-heritage-green-dark-30;
            cursor: pointer;
        }
        
        &:active {
            color: $colour-heritage-green-tint-50;
        }
        
    }
}